import { isDefaultVintageYear } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ORDER_BY_RATING, ORDER_BY_RATINGS_COUNT, ORDER_DESC } from 'vivino-js/api/explore';
import { fetchPrices } from 'vivino-js/api/prices';
import { WINERY_WINES_SORT_BY, fetchWines } from 'vivino-js/api/wineryWines';
import { isPPCPrice } from 'vivino-js/helpers/price';
import t from 'vivino-js/translationString';
import { explorerUrl } from 'vivino-js/vivinoUrls';
import { WineBandSkeleton } from 'vivino-ui/atoms/Skeletons';

import WineBandWithSimpleHeader from 'vivino-js/components/WineBandWithSimpleHeader';
import { Col, Row } from 'vivino-ui/components/Grid';

import styles from './wineryPage.scss';

const TRANSLATIONS_PATH = 'components.winery.wine_list_container';
const TRANSLATIONS = {
  headline: `${TRANSLATIONS_PATH}.headline`,
  mostRated: `${TRANSLATIONS_PATH}.most_popular`,
  bestRated: `${TRANSLATIONS_PATH}.best_rated`,
  fromWinery: `${TRANSLATIONS_PATH}.from_winery`,
  findMoreGreatWines: 'common.find_more_great_wines',
};

const getAvailableVintage = ({ wine, masterVintage, prices }) => {
  let availableVintage;
  const availabileVintageId = prices?.vintages?.[masterVintage.id]?.vintage?.id;
  if (availabileVintageId) {
    availableVintage = wine.vintages.find((vintage) => vintage.id === availabileVintageId);
  }
  if (!availableVintage) {
    availableVintage = masterVintage;
  }
  return {
    ...availableVintage,
    image: wine.image,
    wine,
  };
};

const WineryPage = ({ wineryId, wineryName }) => {
  const [bestRated, setBestRated] = useState([]);
  const [mostRated, setMostRated] = useState([]);
  const [vintageIdsWithPricesMap, setVintageIdsWithPricesMap] = useState({});
  const [prices, setPrices] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const showWineryContactSection = () => {
    // contact section is SSR, to mitigate CLS show it after wine bands finished loading
    document.getElementsByClassName('winery-page__belowTheFold')?.[0].classList?.add('__show');
  };

  const exploreQueryMostRated = {
    orderBy: ORDER_BY_RATINGS_COUNT,
    order: ORDER_DESC,
    minRating: 0,
    priceRangeMin: 0,
    priceRangeMax: 0,
  };

  const exploreQueryBestRated = {
    ...exploreQueryMostRated,
    orderBy: ORDER_BY_RATING,
  };

  const fetchWinesBySortBy = (sortBy) =>
    fetchWines({
      wineryId,
      params: [
        { name: 'per_page', value: 50 },
        { name: 'sort', value: sortBy },
        { name: 'include_all_vintages', value: true },
      ],
    });

  const fetchWinesAndPrices = async () => {
    setIsLoading(true);

    try {
      const [bestRatedData, mostRatedData] = await Promise.all([
        fetchWinesBySortBy(WINERY_WINES_SORT_BY.BEST_RATED),
        fetchWinesBySortBy(WINERY_WINES_SORT_BY.MOST_RATED),
      ]);

      const { mapByWineIds, mapByVintageIds } = [bestRatedData.wines, mostRatedData.wines].reduce(
        (memo, wines) => {
          wines.forEach((wine) => {
            const { id: wineId, vintages } = wine;
            if (!memo.mapByWineIds[wineId]) {
              const masterVintage = vintages.find((vintage) => isDefaultVintageYear(vintage.year));

              /**
               * only include wines with valid ratings for now
               * this needs to be revisited if we have different UI
               * Currently, it doesn't make sense to include wines without valid rating
               * because 2 wine bands look more like a filter
               * but if these are 2 sorting options, we probably should include
               */
              if (wine.has_valid_ratings && masterVintage?.has_valid_ratings) {
                memo.mapByWineIds[wineId] = {
                  wine,
                  masterVintage,
                };
                memo.mapByVintageIds[masterVintage.id] = wineId;
              }
            }
          });
          return memo;
        },
        {
          mapByWineIds: {},
          mapByVintageIds: {},
        }
      );

      const masterVintageIds = Object.keys(mapByVintageIds);
      if (masterVintageIds.length === 0) {
        return;
      }

      const { prices } = await fetchPrices({ vintageIds: masterVintageIds });

      const availableVintagesMap = masterVintageIds.reduce((memo, masterVintageId) => {
        const wineId = mapByVintageIds[masterVintageId];
        const { wine, masterVintage } = mapByWineIds[wineId];
        memo[wineId] = getAvailableVintage({ wine, masterVintage, prices });
        return memo;
      }, {});

      const getFilteredAvailableVintage = (wines) =>
        wines.reduce((memo, wine) => {
          const availableVintage = availableVintagesMap[wine.id];
          if (availableVintage) {
            memo.push(availableVintage);
          }
          return memo;
        }, []);
      setBestRated(getFilteredAvailableVintage(bestRatedData.wines));
      setMostRated(getFilteredAvailableVintage(mostRatedData.wines));

      // Maps the master vintage Ids with the vintage ids for which we get the available price
      const vintageIdsWithPricesMapByMasterVintageIds = {};

      const vintagePrices = masterVintageIds.reduce((memo, masterVintageId) => {
        const vintageAvailabilityResult = prices?.vintages?.[masterVintageId];
        const vintagePrice = vintageAvailabilityResult?.price;

        if (!vintagePrice?.id) {
          return memo;
        }

        const wineId = mapByVintageIds[masterVintageId];
        const availableVintage = availableVintagesMap[wineId];

        vintageIdsWithPricesMapByMasterVintageIds[masterVintageId] =
          vintageAvailabilityResult.vintage.id;

        // For PPC prices, vintagePrice is minimum(?) price,
        // but wine page shows `median` price in PurchaseAvailability module
        // so pass median price when ppc to avoid price discrepancy between this card and wine page
        const medianPrice = vintageAvailabilityResult?.median;
        const price =
          isPPCPrice(vintagePrice) && medianPrice?.amount
            ? {
                ...medianPrice,
                type: 'ppc',
              }
            : vintagePrice;

        memo[availableVintage.id] = {
          ...price,
          currency: prices?.market?.currency,
        };
        return memo;
      }, {});

      setPrices(vintagePrices);
      setVintageIdsWithPricesMap(vintageIdsWithPricesMapByMasterVintageIds);
    } finally {
      showWineryContactSection();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWinesAndPrices();
  }, []);

  // Vintage ids for the explore link on mobile
  const bestRatedPricesVintageIds = bestRated.map(
    (masterVintage) => vintageIdsWithPricesMap[masterVintage.id] || masterVintage.id
  );
  const mostRatedPricesVintageIds = mostRated.map(
    (masterVintage) => vintageIdsWithPricesMap[masterVintage.id] || masterVintage.id
  );

  return (
    <Row>
      <Col>
        <div className={styles.wineBandContainer}>
          {isLoading ? (
            <WineBandSkeleton />
          ) : (
            <WineBandWithSimpleHeader
              header={t(TRANSLATIONS.mostRated)}
              subHeader={t(TRANSLATIONS.fromWinery, { winery: wineryName })}
              prices={prices}
              useLabelShots
              vintages={mostRated}
              showOutOfStock={false}
              showWithoutPrices
              isMasterWineCard
              // @ts-ignore
              showAllUrl={explorerUrl({
                ...exploreQueryMostRated,
                vintageIds: mostRatedPricesVintageIds,
              })}
            />
          )}
        </div>
        <div className={styles.wineBandContainer}>
          {isLoading ? (
            <WineBandSkeleton />
          ) : (
            <WineBandWithSimpleHeader
              header={t(TRANSLATIONS.bestRated)}
              subHeader={t(TRANSLATIONS.fromWinery, { winery: wineryName })}
              prices={prices}
              useLabelShots
              vintages={bestRated}
              showOutOfStock={false}
              showWithoutPrices
              isMasterWineCard
              // @ts-ignore
              showAllUrl={explorerUrl({
                ...exploreQueryBestRated,
                vintageIds: bestRatedPricesVintageIds,
              })}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

WineryPage.propTypes = {
  wineryName: PropTypes.string.isRequired,
  wineryId: PropTypes.number.isRequired,
};

export default WineryPage;
