import { apiGet } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export const WINERY_WINES_SORT_BY = {
  BEST_RATED: 'best_rated',
  MOST_RATED: 'most_rated',
};

export const fetchWines = ({ wineryId, params = [] }) => {
  const url = generateQueryUrl({
    baseUrl: `/api/wineries/${wineryId}/wines`,
    params,
  });

  return apiGet({ url });
};
