import React from 'react';
import ReactDOM from 'react-dom';
import {
  CART_ITEM_SOURCE_TYPES,
  CartItemSourceContext,
} from 'vivino-js/context/CartItemSourceContext/CartItemSourceContext';
import { isDE, isFR, isGB, isIT, isNL, isUS } from 'vivino-js/helpers/countryHelpers';
import t from 'vivino-js/translationString';
import Page from 'vivino-js/utilities/Page';

import WineryPage from './components/WineryPage';
import ExploreWidget from 'vivino-js/components/ExploreWidget';
import { Col, Row } from 'vivino-ui/components/Grid';

const TRANSLATIONS = {
  findMoreGreatWines: 'common.find_more_great_wines',
};
function render() {
  const { wineryId, wineryName, priceRange } = window.__PRELOADED_STATE__;

  ReactDOM.render(
    <Page>
      <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.WINERY_PAGE_ROOT}>
        <WineryPage wineryId={wineryId} wineryName={wineryName} />
      </CartItemSourceContext.Provider>
    </Page>,
    document.getElementById('wineries-page')
  );

  ReactDOM.render(
    <Page>
      <Row>
        <Col>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.WINERY_PAGE}>
            <ExploreWidget
              headerText={t(TRANSLATIONS.findMoreGreatWines)}
              priceRange={priceRange}
              trackingContext="Winery Page"
            />
          </CartItemSourceContext.Provider>
        </Col>
      </Row>
    </Page>,
    document.getElementById('explore-widget')
  );

  const countryCode = window.__PRELOADED_COUNTRY_CODE__;
  if (isUS(countryCode)) {
    window.hj('trigger', 'winery-us-poll');
  }

  if (
    isGB(countryCode) ||
    isDE(countryCode) ||
    isFR(countryCode) ||
    isIT(countryCode) ||
    isNL(countryCode)
  ) {
    window.hj('trigger', 'winery-poll-eu');
  }
}

render();

export { render };
